import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/hepato-silimarin-header.jpg"
import LuteinComplexTablet from "./../../images/hepato-silimarin-header.jpg"
import LuteinComplexDesktop from "./../../images/hepato-silimarin-header.jpg"
import LuteinComplexXL from "./../../images/hepato-silimarin-header.jpg"

import Silimarin from "./../../images/hepato-silimarin-inside.jpg"

const HepatoSilimarin = () => (
  <Layout backButton={true} pageInfo={{ pageName: "hepato-silimarin" }}>
    <Seo title="Силимарин: силен антиоксидант и хепатопротектор" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              СИЛИМАРИН: СИЛЕН АНТИОКСИДАНТ И ХЕПАТОПРОТЕКТОР
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Силимарин: силен антиоксидант и хепатопротектор"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Силимарин: силен антиоксидант и хепатопротектор
          </h1>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <p>
            Силимаринът е уникален флавоноиден комплекс, съдържащ силибин,
            силидианин и силихристин, извличан от растението бял трън. Тези
            уникални фитохимикали на белия трън са предмет на десетилетия
            проучване поради техните полезни свойства. През 1968 група немски
            учени откриват активния флавоноиден комплекс, наречен силимарин,
            който дава на белия трън полезните свойства.
          </p>
          <p>
            От тогава, стотици изследвания са направени върху силимарина и е
            одобрен от немската Кommission-Е Мonographien (най-точната налична
            информация за безопасност и ефикасност на билки) като поддържаща
            терапия за състояния на възпален черен дроб като цироза, хепатит и
            мастни натрупвания, причинени от алкохол и други токсини.
          </p>
          <p>Силимаринът се използва за:</p>
          <ul>
            <li>
              Регенериране на чернодробни клетки, повредени от алкохол и
              лекарства;
            </li>
            <li>Повишаване преживяемостта на пациенти с цироза;</li>
            <li>Допълва лечението на вирусен хепатит;</li>
            <li>
              Защитава срещу промишлени отрови, като въглероден тетрахлорид
              (безцветен газ, изтичащ се във въздуха, водата и почвата в близост
              до промишлени места и отпадъчни депа);
            </li>
            <li>
              Защитава черния дроб от лекарства, причиняващи му стрес, като
              ацетаминофен и тетрациклин.
            </li>
          </ul>
        </Col>
        <Col sm={7}>
          <h4>Как работи силимаринът?</h4>
          <ul>
            <li>
              Като антиоксидант, силимаринът търси свободни радикали, които
              могат да увредят клетките, изложени на токсини. Счита се, че
              силимаринът има десет пъти по-силна антиоксидантна способност от
              Витамин Е.
            </li>
            <li>
              Повишава глутатиона в черния дроб с над 35% при здрави индивиди.
              Глутатионът е отговорен за детоксикация на много хормони,
              лекарства и химикали. Високи нива на глутатион в черния дроб
              увеличават капацитета за детоксикация.
            </li>
            <li>
              Силимаринът също увеличава нивото на важния антиоксидантен ензим
              супероксид дисмутаза в клетъчните култури.
            </li>
            <li>
              Стимулира протеиновия синтез в черния дроб, в резултат на което се
              повишава продукцията на нови чернодробни клетки, заменящи тези,
              които са увредени.
            </li>
            <li>
              Силимаринът инхибира синтеза на левкотриени (медиатори на
              възпалението, които биха могли да доведат до псориазис).
            </li>
          </ul>
        </Col>
        <Col sm={5}>
          <Image className="w-100" src={Silimarin} />
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <h4>Научни изследвания</h4>
          <p>
            Силимаринът е с доказан успех при лечение на чернодробни състояния
            причинени от алкохола. В изследване, учените установяват ползите на
            екстракта от бял трън върху 170 пациента, 91 от тях алкохолици с
            цироза на черния дроб. Лицата са получавали 140 мг силимарин три
            пъти дневно в продължение на 41 месеца. 4-годишният процент на
            преживяемост е бил 58% за групата със силимарин и 39 в плацебо
            групата. Намалената смъртност сред тези, приемащи силимарин, била
            най-изявена в подгрупата на причинената от алкохол цироза. Нямало е
            странични ефекти от силимарина.
          </p>
          <p>
            Изследването е важно поради няколко причини. Тъй като не е имало
            странични ефекти, резултатите поддържат идеята, че дългосрочното
            лечение е от полза и е безвредно. Тези резултати също сочат, че
            силимаринът би могъл да е особено ефективен за пациенти с
            алкохолно-увреден черен дроб.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <h4>Противоотрова на токсините в околната среда</h4>
          <p>
            Д-р Джеймс Дюк, водещ специалист в лечебните растения, твърди „Дори
            да нямате увреждания или болести на черния дроб, белият трън
            подобрява функцията на черния дроб, помагайки за освобождаване на
            токсините от тялото ви.” В този модерен свят, изпълнен с химически
            токсини и такива от околната среда, силимаринът е антиоксидантът,
            който би бил от полза да добавите към режима си.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>Можете да откриете ХЕПАТОФЕЛИН в най-близката до вас аптека.</p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/hepatofelin">
              Повече информация за ХЕПАТОФЕЛИН можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default HepatoSilimarin
